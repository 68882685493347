
.footer__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer__form-row {
    display: flex;
    width: 100%;
  
    &:first-child {
      margin-bottom: 0.937rem;
    }
  
    &:nth-child(2) {
      margin-bottom: 1.812rem;
    }
  
    &--two {
      justify-content: space-between;
  
      .footer__label {
        width: 48.999%;
      }
    }
  }
  
  .footer__label {
    width: 100%;
    display: block;
  }
  
  .footer__input {
    display: block;
    width: 100%;
    padding: 1rem 2.062rem;
    border-radius: 3.125rem;
  
    border: 1px solid var(--primary-black);
    background-color: transparent;
    color: var(--primary-black);
  
    &.fz-19 {
      font-weight: 400;
    }
  
    &--message {
      min-height: 8.187rem;
      border-radius: 1.875rem;
    }
  
    &::placeholder {
      color: var(--primary-scorpion);
    }
  }
  
  .footer__form-button {
    display: block;
    padding: 0.937rem 1.937rem;
    border-radius: 3.125rem;
  
    border: none;
    background-color: var(--primary-black);
    color: var(--primary-happy-yellow);
    cursor: pointer;
    transition: var(--transition);
  
    &.fz19 {
      font-weight: 400;
    }
  
    &:hover {
      background-color: var(--primary-hot-pink);
      color: var(--primary-white);
    }
  }

  .error-text {
    margin: 0;
    margin-left: 22px;
  }

  .success-text {
    margin: 0;
    margin-bottom: 20px;
  }