.utilites {
  padding: 120px 0 0;
  position: relative;
  @media (max-width: 1200px) {
    padding-top: 120px;
  }
  @media (max-width: 768px) {
    padding-top: 120px;
  }
  &__border-top {
    position: absolute;
    top: 0;
    width: 100%;
    transform: translate(0, -92%);
  }
  &__wrap {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 120px;
    @media (max-width: 1024px) {
      justify-content: space-between;
      margin-bottom: 120px;
      gap: 32px 16px;
    }
   
  }
  &__title {
    color: var(--primary-white);
    font-family: "Norwester", sans-serif;
    font-size: 180px;
    line-height: 3.915rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    @media (max-width: 1400px) {
      font-size: 120px;
    }
    @media (max-width: 567px) {
      font-size: 80px;
    }
  }

  &__border {
    width: 100%;
    position: absolute;
    top: 0;
    transform: translate(0, -100%);
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}

.card {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 22px 14px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  min-height: 296px;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    min-height: 260px;
    text-align: center;
  }
  &:hover {
    .card__btn {
      transform: translateY(0);
    }
  }
  &__wrap {
    width: calc(100% / 5 - 16px);
    overflow: hidden;
    @media (max-width: 1400px) {
      width: calc(100% / 2 - 16px);
    }
    @media (max-width: 567px) {
      width: 100%;
    }
    a {
      color: var(--white);
      font-weight: bold;
    }
  }
  &__desc {
    color: var(--white);
  }
  h3 {
    margin-top: auto;
    font-family: "Norwester", sans-serif;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 0;
    color: var(--black-third);
    text-transform: uppercase;
    @media (max-width: 567px) {
      text-align: left;
      font-size: 20px;
      line-height: 26px;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 18px;
    font-family: "Space Grotesk", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--black-third);
    text-align: center;
  }
  &__btn {
    transform: translateY(100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--black);
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: none;
    text-transform: uppercase;
    text-align: center;
    color: var(--white);
    text-decoration: none;
    transition: transform 0.3s;
    svg {
      margin: 0 auto 20px auto;
      width: 30px;
      height: 30px;
      fill: var(--white);
    }
  }
  &_see {
    flex-direction: row;
    justify-content: center;
  }

  &_pink {
    background-color: var(--pink);
  }
  &_yellow {
    background-color: var(--primary-happy-yellow);
  }
  &_black {
    background-color: var(--black);
  }
}
