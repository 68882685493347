.universe {
  position: relative;
  height: 1350px;
  overflow: hidden;
  background-color: #010101;
  background-image: url("../../assets/img/universe/building.png");
  background-position: 0 -100px;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 567px) {
    height: 860px;
  }
  @media (min-width: 1700px) {
    height: 1450px;
  }
  &__block {
    margin-top: 320px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Norwester";
    font-size: 26px;
    @media (max-width:992px) {
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin-bottom: 150px;
    }
  }
  &__text {
    transition: transform 1s, opacity 1.3s;
    opacity: 0;
    transform: translateY(-200px);
    color: var(--primary-white);
    &_animate {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__thei {
    transition: transform 1s, opacity 1.3s;
    opacity: 0;
    transform: translateY(200px);
    &_animate {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__bg {
    width: 100%;
    height: 100%;
  }
  &__cloud {
    position: absolute;
    bottom: 0;
    transition: transform 1.2s, opacity 0.1s;
    overflow: hidden;
    &-wrap {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
    &:nth-child(1) {
      width: 40vw;
      left: 0;
      bottom: 200px;
    }
    &:nth-child(2) {
      width: 50vw;
      right: 0;
    }
    &_animate {
      &:nth-child(1) {
        transform: translate(-200px, -100px);
      }
      &:nth-child(2) {
        transform: translate(200px, -100px);
      }
    }
  }
}
