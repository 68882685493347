.collection {
  &__desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    font-family: "Norwester";
    color: var(--primary-white);
    font-size: 26px;
    text-transform: uppercase;
  }
}

.collection-swiper {
  position: static !important;
  width: 375px;
  padding-bottom: 40px !important;
  @media (max-width:968px) {
    width: 100%;
    margin: auto;
  }
  .swiper-pagination {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    background-color: var(--primary-white);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: var(--primary-happy-yellow);
    }
  }
}

.collection-swiper__container {
  position: relative;
  max-width: 375px;
  @media (max-width:968px) {
    width: 100%;
    margin: auto;
  }
}

.collection-next,
.collection-prev {
  border: none;
  cursor: pointer;

  background-color: transparent;

  svg {
    fill: var(--primary-white);
    stroke: var(--primary-white);
    max-width: 100%;
    max-height: 100%;
  }



  &:hover {
    svg {
      fill: var(--primary-hot-pink);
      stroke: var(--primary-hot-pink);
    }
  }
}

.collection-next {
  right: 0;
}

.collection-prev {
  left: 0;
}

.collection-swiper__img {
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
