.questions {
  padding-top: 5.937rem;
  padding-bottom: 5.937rem;
  position: relative;
  &__title {
    margin-top: 0;
    margin-bottom: 1.625rem;
    color: var(--primary-white);
    font-family: "Nafta Light", sans-serif;
    font-size: 2.5rem;
    line-height: 2.687rem;
    @media (max-width: 1024px) {
      text-align: center;
    }
    @media (max-width: 768px) {
      margin-bottom: 4rem;
    }
  }
  @media (max-width: 1024px) {
    padding-bottom: 5.937rem;
  }
  &__border {
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: translate(0, 98%);
  }
}

.questions__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
}

.questions__list-container {
  width: 35%;

  @media (max-width: 1024px) {
    order: 2;
    width: 100%;
    margin: 0 auto;
  }

}

.questions__list-link {
  display: inline-flex;
  justify-content: space-between;
  min-width: 264px;
  margin-top: 52px;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: var(--primary-white);
  background-color: var(--primary-hot-pink);
  color: var(--primary-white);
  transition: var(--transition);

  text-decoration: none;
  svg {
    fill: var(--primary-white);
  }
  &:hover {
    background-color: var(--primary-white);
    svg {
      fill: var(--primary-black);
    }

    .questions__link-text {
      color: var(--primary-black);
    }
  }

  @media (max-width: 1024px) {
    min-width: 34.591%;
  }

  @media (max-width:567px) {
    width: 100%;
  }
}

.questions__link-text {
  &.fz-19 {
    font-weight: 400;
  }

  margin-right: 1rem;
}

.questions__link-icon {
  position: relative;
  width: 1.25rem;

  &::before {
    content: "";
    display: block;
    padding-top: 90%;
  }
}

.questions__image-container {
  width: 65%;

  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 667px) {
    width: 100% !important;
  }
}

.questions__answer-container {
  position: relative;
  display: flex;
  width: 100%;
  background-image: url("../../assets/img/questions/iphone12.png");
  background-repeat: no-repeat;
  background-position: right;

  &::before {
    content: "";
    display: block;
    padding-top: 65%;
  }

  &--animate {
    animation-name: imageAnim;
    animation-duration: var(--transition-slow);
  }
}

.questions__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  height: min-content;
  width: 46.374%;
}

.questions__content-icon {
  position: relative;
  display: block;
  width: 7.25rem;
  margin-bottom: 14px;

  &::before {
    content: "";
    display: block;
    padding-top: 56.896%;
  }
}

.questions__content-title {
  margin-top: 0;
  margin-bottom: 34px;
  color: var(--primary-hot-pink);
  font-family: 'Norwester';
  font-size: 6.5rem;
  line-height: 6.465rem;
  text-transform: uppercase;
}

.questions__content-link {
  display: inline-flex;
  justify-content: space-between;
  min-width: 260px;
  padding: 0.937rem 1.312rem 0.937rem 2rem;
  border-radius: 32px;

  background-color: var(--primary-white);
  text-decoration: none;
  transition: var(--transition);

  &:hover {
    background-color: var(--primary-hot-pink);

    .questions__content-link-text {
      color: var(--primary-white);
    }

    .questions__content-link-icon {
      fill: var(--primary-white);
    }
  }
}

.questions__content-link-text {
  margin-right: 0.75rem;
  color: var(--primary-black);

  &.fz-19 {
    font-weight: 400;
  }
}

.questions__content-link-icon {
  position: relative;
  width: 1.125rem;

  svg {
    fill: var(--primary-black);
  }

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.questions__answer-img {
  display: none;
  position: absolute;
  right: 0;
  width: 50%;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
