
.mint-info {
    background-color: var(--white);
    padding: 12px 0;
    &__wrap {
      display: flex;
      justify-content: space-between;
      gap: 90px;
      @media (max-width: 568px) {
        padding: 40px 0;
        gap: 60px;
        flex-direction: column;
      }
    }
    &__block {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      width: calc(100% / 2 - 20px);
      max-width: 700px;
      font-family: "Space Grotesk";
      font-size: 19px;
      @media (max-width: 568px) {
        width: 100%;
      }
      p {
        @media (max-width: 1400px) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      @media (max-width: 1400px) {
        flex-direction: column;
        text-align: center;
      }
      &-title {
        display: flex;
        align-items: center;
        gap: 26px;
        @media (max-width: 1400px) {
          flex-direction: column;
          text-align: center;
          gap: 0;
        }
        h3 {
          font-family: 'Norwester';
          font-size: 40px;
          line-height: 42px;
          text-transform: uppercase;
          color: var(--pink);
          margin: 0;
          @media (max-width: 1400px) {
            margin-bottom: 0;
          }
        }
  
        img {
          width: 75px;
        }
      }
    }
  }
  