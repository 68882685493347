.whitepaper {
  h4 {
    scroll-margin-block-start: 100px;
  }
  &__text {
    font-family: "Space Grotesk", sans-serif;
    color: var(--white);
    a {
      color: var(--white);
    }
    h3:not(:first-child) {
        margin-top: 60px;
    }
    h4 {
      text-transform: uppercase;
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }
  }
  &__links {
    p {
      margin-bottom: 0;
    }
    margin-bottom: 60px;
  }
}
