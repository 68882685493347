.slider {
  position: relative;
  color: var(--white);
  &__border {
    width: 100%;
    position: absolute;
    top: 0;
    transform: translate(0, -97%);
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 100px;
    }
    @media (max-width: 567px) {
      gap: 20px;
    }
  }
  &__info {
    &-head {
      display: flex;
      justify-content: space-between;
    }
  }
}

.info {
  max-width: 600px;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    @media (max-width:400px) {
      flex-direction: column;
      align-items: baseline;
      gap: 20px;
      
    }
  }
  &__title {
    color: var(--yellow);
    font-size: 40px;
    font-family: "Norwester";
    text-transform: uppercase;
    margin: 0;
  }
  &__btns {
    margin-top: 44px;
    display: flex;
    gap: 16px;
    @media (max-width: 567px) {
      flex-direction: column;
    }
  }
  &__btn {
    &_next {
      display: flex;
      gap: 18px;
      align-items: center;
      color: var(--white);
      background: none;
      border: none;
      text-transform: uppercase;
    }
    &_story {
      background-color: var(--pink);
      color: var(--white);
      &:hover {
        color: var(--black);
        svg {
          fill: var(--black);
        }
      }
      svg {
        width: 20px;
        fill: var(--white);
      }
    }
    &_share {
      &:hover {
        background-color: var(--pink);
        color: var(--white);
        svg {
          fill: var(--white);
        }
      }
      svg {
        width: 24px;
      }
    }
  }
}

.images {
  display: flex;
  justify-content: center;
  &__prev,
  &__next {
    opacity: 0.5;
    transform: scale(0.7);
    @media (max-width:767px) {
      display: none;
    }
  }
  &__active {
    transform: scale(1.3);
    @media (max-width: 768px) {
      transform: scale(1);
    }
  }
}

.scroll-text {
  overflow: hidden;
  font-family: "Norwester";
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  text-transform: uppercase;
  color: var(--gray);
  @media (max-width: 567px) {
    font-size: 60px;
    margin-bottom: 40px;
  }
  h2 {
    white-space: nowrap;
    animation: runing 10s linear infinite;
    margin: 30px 0 0 0;
  }
}

@keyframes runing {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
