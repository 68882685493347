.poll {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--primary-black);
  color: var(--primary-white);
  padding: 30px 60px 60px;
  border-radius: 30px;
  font-family: "Space Grotesk";
  font-size: 19px;
  line-height: 27px;
  max-height: calc(100vh - 100px);
  overflow: auto;
  width: 960px;
  visibility: hidden;
  &_show {
    visibility: initial;
  }
  @media (max-width: 960px) {
    width: 99%;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
  &__title {
    font-size: 40px;
    text-align: center;
    font-family: "Nafta Light";
    text-transform: uppercase;
    margin-bottom: 60px;
  }

  &__done {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    &_show {
      min-height: 400px;
      height: initial;
      visibility: visible;
    }
    h2 {
      font-size: 40px;
      font-weight: 700;
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin-top: 40px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__block {
    width: calc(100% / 3 - 24px);
    @media (max-width: 768px) {
      width: 100%;
    }
    &__head {
      background-color: var(--primary-white);
      width: 100%;
      min-height: 80px;
      color: var(--primary-black);
      padding: 10px;
      margin-bottom: 30px;
      border: transparent solid 2px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      &_active {
        border: solid 2px var(--primary-hot-pink);
      }
    }
    h3 {
      font-size: 22px;
      font-family: "Space Grotesk";
      text-transform: uppercase;
      color: var(--primary-hot-pink);
      font-weight: 700;
      text-align: left;
      margin: 0;
      @media (max-width: 960px) {
        font-size: 18px;
      }
    }
    img {
      width: 50px;
    }
  }
  &__btn {
    background-color: var(--primary-hot-pink);
    width: 246px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: none;
    padding: 20px;
    color: var(--primary-white);
    font-size: 19px;
    font-family: "Space Grotesk";
    margin: auto;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: var(--primary-white);
      color: var(--primary-black);
    }
  }
  &__close {
    width: 30px;
    height: 30px;
    background-color: initial;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border: none;
    @media (max-width: 768px) {
      position: sticky;
      border-radius: 50%;
    }
    svg {
      fill: var(--primary-happy-yellow);
      width: 24px;
    }
  }
  &__free {
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: center;
  }
  &__btns-wrap {
    display: flex;
    gap: 20px;
    & > *:first-child {
      flex: 1;
    }
    & > *:last-child {
      flex: 1;
      text-align: right;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__skip {
    background-color: initial;
    color: var(--primary-white);
    border: none;
    font-size: 18px;
    font-family: Space Grotesk;
    cursor: pointer;
  }
}

.share {
  position: relative;
  margin: auto;
  text-align: center;
  @media (max-width: 768px) {
    right: 0;
    top: 7px;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 60px;
    width: 60px;
    border: none;
    border-radius: 50%;
    transition: color 0.3s, background-color 0.3s;
    background-color: #E6007E;
    color: var(--primary-white);
    cursor: pointer;
    &:hover {
      background-color: var(--primary-white);
      color: var(--primary-black);
    }
  }
  &-btns {
    visibility: hidden;
    background-color: black;
    padding: 14px 20px;
    border-radius: 50px;
    &_active {
      visibility: visible;
    }
  }
  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -8px;
    left: 50%;
    width: 24px;
    height: 24px;
    transform: translateX(-50%);
    background: none;
    background-color: #000;
    border-radius: 50%;
    color: var(--primary-white);
    border: none;
    cursor: pointer;
    div {
      transition: 0.3s transform;
      &:hover {
        transform: rotate(360deg);
      }
    }
  }
}
