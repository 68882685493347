.questions__list {
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: var(--transition);
}

.questions__item {
  border-bottom: 1px solid var(--primary-white);
}

.questions__item-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.218rem 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: normal;
}

.questions__button-text {
  display: flex;
  align-items: center;
  color: var(--primary-white);
  display: flex;
  gap: 30px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.687rem;
  line-height: 2.642rem;
  text-align: left;

  &--active {
    color: var(--primary-happy-yellow);
  }
}

.open-btn {
  position: relative;
  height: 1.712rem;
  width: 1.712rem;

  &--active {
    .open-btn__line--top {
      transform: translateY(-50%);
    }
  }
}

.open-btn__line {
  transition: var(--transition-slow);
  position: absolute;
  height: 0.228rem;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  transform: rotate(0) translateY(-50%);
  background-color: var(--primary-white);

  &--top {
    transform: rotate(-450deg) translateX(1px);
  }
}

.questions__answer-dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height .8s;
  &_active {
    max-height: 1000px;
  }
}

.questions__answer {
  color: var(--primary-white);
  transition: var(--transition) ease height;

  overflow: hidden;
  a {
    color: var(--white);
  }
  &.fz-19 {
    font-weight: 400;
  }

  &--animate {
    //animation-name: dropdownAnim;
    //animation-duration: var(--transition);
    max-height: 9000px;
  }
}


