.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  animation-name: fadeInTop;
  animation-duration: .7s;
}

.popup__close-btn {
  position: fixed;
  right: 3%;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  p {
    margin: 0;
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--primary-happy-yellow);
  }
  svg {
    width: 27px;
    height: 27px;
    fill: var(--primary-happy-yellow);
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.popup__container {
  padding-bottom: 6.25rem;

  &--mint {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;

    @media (max-width: 667px) {
      flex-direction: column-reverse;
    }
  }
}

.popup__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0 40px;
  &--mint {
    padding: 3.437rem 3.875rem 0;
  }
}

.popup__title {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--primary-white);

  font-family: 'Norwester', sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 2.55rem;
}