.header-right__icons {
  display: flex;
  align-items: center;

  margin: 0 0 0 auto;
  padding: 0;

  list-style-type: none;

  @media (max-width: 1024px) {
    margin: 0;
  }
}

.header-right__icons-item {
  &:not(:last-child) {
    margin-right: 0.946rem;
  }
}

.header-right__icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.921rem;
  height: 1.921rem;
  border-radius: 100%;
  background-color: transparent;
  transition: var(--transition);
  
  &:hover {
    background-color: var(--white);

    svg {
      fill: var(--primary-black);
    }
  }

  @media (max-width: 667px) {
    width: 2.921rem;
    height: 2.921rem;
  }
}


.header__right-icon-container {
  position: relative;
  transition: var(--transition);

  svg {
    fill: var(--white);
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    display: block;
  }
}

.header-right__icon-container--discord {
  width: 18.4px;
  &::before {
    padding-top: 76.141%;
  }
}

.header-right__icon-container--insta {
  width: 14.15px;
  z-index: 10;

  &::before {
    padding-top: 100%;
  }
}

.header-right__icon-container--twitter {
  width: 13.09px;
  &::before {
    padding-top: 82.123%;
  }
}

.header-right__icon-container--tiktok {
  width: 12.08px;
  &::before {
    padding-top: 116.804%;
  }
}