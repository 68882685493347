.collection {
  padding-top: 5.187rem;
  padding-bottom: 4.687rem;
  background-color: var(--section-bg-sec);
  @media (max-width: 567px) {
    padding-bottom: 4.687rem;
  }
  &__sidebar-l {
    padding-top: 136px;
    @media (max-width: 968px) {
      padding-top: 40px;
    }
  }
  &__title {
    font-size: 40px;
    font-family: "Norwester";
    color: var(--yellow);
    text-transform: uppercase;
    @media (max-width: 567px) {
      font-size: 26px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    @media (max-width: 968px) {
      flex-direction: column;
      justify-content: center;
    }
    @media (max-width:567px) {
      flex-direction: column-reverse;
    }
  }
}

.slider {
  &__btn {
    display: flex;
    gap: 18px;
    font-size: 18px;
    background: none;
    border: none;
    color: var(--white);
    text-transform: uppercase;
  }
  &__text {
    max-width: 600px;
    color: var(--white);
    animation: fadeInLeft 0.5s;
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a {
      color: var(--white);
    }
  }
  &__slide {
    position: relative;
    max-width: 600px;
  }
  &__img {
    position: absolute;
    bottom: 5px;
    left: -10%;
    animation: fadeInBottom 0.5s;
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 65px 0 10px 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 23px;
    @media (max-width: 567px) {
      font-size: 20px;
    }
  }
  &__info {
    &-item {
      $items: 1 "41%" "46%", 2 "28%" "38%", 3 "22%" "39%", 4 "15%" "68%",
        5 "21%" "57%", 6 "33.6%" "62%", 7 "28.6%" "69%", 8 "24.6%" "74%",
        9 "7.6%" "41%", 10 "5%" "50%", 11 "16%" "15%", 12 "13%" "52%";
      position: absolute;
      left: 0;
      @each $idx, $bottom, $left in $items {
        &:nth-child(#{$idx}) {
          bottom: #{$bottom};
          left: #{$left};
        }
      }
      &:hover {
        .slider__info-desc {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &-dot {
      background: #ffffff;
      border: 1px solid #000000;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      width: 22px;
      height: 22px;
      transition: transform 0.3s;
      animation: fadeInBottom 0.7s;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        background-color: var(--pink);
      }
    }
    &-desc {
      position: absolute;
      right: calc(-100% + 90px);
      top: 0;
      z-index: 1000000;
      min-width: 220px;
      padding: 34px 28px;
      transform: translate(0, -70%);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.7s;
      background: var(--white);
      box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      @media (max-width: 968px) {
        left: 0;
        padding: 12px;
        transform: translate(-50%, 50%);
        min-width: max-content;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: 100;
        right: 0;
        transform: translate(98%, -102%);
        border-top: 32px solid transparent;
        border-left: 42px solid var(--white);
        @media (max-width: 968px) {
          display: none;
        }
      }
      h3 {
        font-size: 20px;
        font-size: normal;
        margin: 0 0 6px;
        text-transform: uppercase;
        font-family: "Norwester";
        @media (max-width: 968px) {
          font-size: 16px;
        }
      }
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translate(0, 50%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0%);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
