.social {
  padding: 60px 0 0;
  position: relative;
  background-color: var(--section-bg-sec);
  &__link {
    text-decoration: none !important;
    font-weight: 400 !important;
  }
  @media (max-width: 1024px) {
    padding-top: 40px;
  }
  &__wrap {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 120px;
    @media (max-width: 1024px) {
      margin-bottom: 100px;
    }
  }
  &__title {
    color: var(--yellow);
    font-family: "Norwester", sans-serif;
    font-size: 40px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  &__desc {
    max-width: 1200px;
    margin: auto auto 100px auto;
    font-family: "Norwester";
    text-align: center;
    font-size: 80px;
    color: var(--white);
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  &__border {
    width: 100%;
    position: absolute;
    top: 0;
    transform: translate(0, -100%);
  }
  &__head {
    text-align: center;
    margin-bottom: 36px;
  }

  &-card {
    background-color: #2e2d2d;
    padding: 22px 28px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    min-height: 376px;
    margin-bottom: 36px;
    transition: background-color 0.3s;
    &:hover {
      background-color: var(--black);
    }
    @media (max-width: 992px) {
      min-height: 320px;
      margin-bottom: 0;
    }
    @media (max-width: 567px) {
      min-height: initial;
    }
    &__wrap {
      width: calc(100% / 4 - 16px);
      @media (max-width: 1024px) {
        width: calc(100% / 2 - 16px);
      }
      @media (max-width: 567px) {
        width: 100%;
      }

      a {
        color: var(--white);
        text-decoration: underline;
        font-weight: bold;
      }
    }
    &__desc {
      color: var(--white);
    }
    h3 {
      margin-top: auto;
      font-family: "Norwester", sans-serif;
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 0;
      color: var(--black-third);
      text-transform: uppercase;
    }
    p {
      margin-top: 0;
      margin-bottom: 18px;
      font-family: "Space Grotesk", sans-serif;
      color: var(--white);
      text-align: center;
      font-size: 18px;
    }
    &__btn {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      background: none;
      color: var(--white);
      border: none;
      text-transform: uppercase;
      text-align: center;
      &__desc {
        margin: auto;
      }
      svg {
        margin: 0 auto 20px auto;
        width: 30px;
        height: 30px;
        fill: var(--white);
      }
    }
    &_see {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &_pink {
      background-color: var(--pink);
    }
    &_yellow {
      background-color: var(--primary-happy-yellow);
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary-hot-pink);
      border-radius: 50%;
      width: 70px;
      height: 70px;
      margin: 0 auto 26px auto;
    }
  }
}

.get-citizen {
  text-align: center;
  &__bg {
    position: relative;
  }
  &__building {
    position: absolute;
    z-index: 10;
    opacity: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: transform 0.5s, opacity 0.5s;
    &_animate {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  &__protester {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    transition: opacity 1.5s;
    opacity: 0;
    @media (max-width: 1200px) {
      width: 50vw;
    }
    &_animate {
      opacity: 1;
    }
  }
  &__btn {
    margin: auto;
    margin-bottom: 20px;
    @media (max-width:567px) {
      width: 100%;
    }
  }
}
