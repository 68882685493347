.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 0.943rem;
  padding-bottom: 2.343rem;
  transition: var(--transition);
  z-index: 10000;

  &--active {
    position: relative;
    background-color: var(--black-sec);
    padding: 0.3rem 0;
    .logo img {
      max-width: 60px;
    }
  }

  &--scroll {
    position: fixed;
    background-color: var(--black-sec);
    padding: 0.6rem 0;
    .logo img {
      max-width: 60px;
    }
  }
}

.header--mobile {
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  .header-right__button {
    margin-bottom: 0;
  }

  @media (max-width: 667px) {
    .logo {
      order: 1;
    }

    .header-right__button {
      order: 2;
    }

    .header-right__icons {
      order: 3;

    }

    .hamburger {
      order: 4;
    }
  }

}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  margin: 0 auto;
}

.header__wrapper-mobile {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: var(--black-sec);
    z-index: 100;

    &--active {
      display: flex;
    }
  }
}

.nav__close-btn {
  display: none;

  @media (max-width: 1024px) {
    position: absolute;
    top: 3rem;
    right: 3rem;
    padding: 0;
    width: 1.712rem;
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    svg {
      fill: var(--white);
    }
  }
}

.logo {
  position: relative;
  display: block;
  padding: 0;
  border: none;
  outline: none;
  width: 70px;
  cursor: pointer;

  background-color: transparent;

  img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &--mobile {
    display: none;
    margin-left: 0;
  }

  &:hover {
    animation-name: rotateHeader;
    animation-duration: var(--transition-slow);
  }

  @media (max-width: 1024px) {
    order: 1;
    margin-left: 0;
    margin-bottom: 2rem;

    &--mobile {
      order: unset;
      display: block;
      margin-bottom: 0;
    }
  }
}

.header-right {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 1024px) {
    order: 3;
    flex-direction: column;
    align-items: center;
  }
}

.header-timer {
  &:not(.mobile) {
    a {
      text-decoration: none;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.header-right__button {
  display: block;
  padding: 0.737rem 1.25rem;
  border-radius: 2.625rem;
  min-width: 38%;
  background-color: var(--primary-hot-pink);
  border: 0.125rem solid var(--primary-black);
  color: var(--primary-hot-pink);
  transition: var(--transition);
  cursor: pointer;
  white-space: nowrap;
  color: var(--primary-black);
  font-family: "Space Grotesk", sans-serif;
  &_black {
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: var(--primary-black);
    color: var(--primary-white);
    border: 0.125rem solid var(--primary-hot-pink);
    span {
      display: block;
      border-style: solid;
      border-width: 10px 0 10px 18px;
      background: transparent;
      border-color: transparent transparent transparent var(--primary-white);
    }
  }
  &:hover,
  &--active {
    background-color: var(--primary-hot-pink);
    color: var(--primary-white);
  }

  @media (max-width: 1024px) {
    margin-bottom: 2rem;
  }
}

.hamburger {
  position: relative;
  display: none;
  flex-direction: column;
  width: 3rem;
  height: 1.5rem;
  border: none;
  cursor: pointer;
  z-index: 20;

  background-color: transparent;

  svg {
    fill: var(--white);
    stroke: var(--white);
  }

  @media (max-width: 1024px) {
    display: flex;
  }
}

.hamburger__line {
  display: block;
  width: 100%;
  height: 0.25rem;
  border-radius: 0.625rem;
  transition: var(--transition);

  background-color: var(--primary-black);

  &--middle {
    margin-top: auto;
    margin-bottom: auto;
  }
}

#hamburger__check {
  display: none;

  &:checked + .hamburger .hamburger__line {
    margin-top: 0;
    margin-bottom: 0;
    background-color: var(--primary-black);

    &--top {
      transform: rotate(45deg) translateY(2px);
    }

    &--bottom {
      transform: rotate(-45deg) translateY(-2px);
    }

    &--middle {
      display: none;
    }
  }

  &:checked ~ .header__wrapper-mobile {
    display: flex;
  }
}

.btn_story {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid var(--white) 2px;
  background: none;
  text-align: center;
  border-radius: 32px;
  height: 60px;
  width: 248px;
  color: var(--white);
  font-weight: 700;
  transition: 0.3s background;
  text-decoration: none;
  line-height: 30px;
  @media (max-width: 1024px) {
    display: none;
  }
  &:hover {
    background-color: var(--white);
    color: var(--black);
  }
}
