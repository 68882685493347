.chapter {
  position: relative;
  background-image: url("../../assets/img/chapter-one/texture.png");
  background-color: var(--pink);
  background-repeat: no-repeat;
  padding-block-start: 180px;
  padding-block-end: 220px;
  @media (max-width: 567px) {
    padding-block-start: 40px;
    padding-block-end: 80px;
  }
  &__border {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.slide {
  position: relative;
  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    @media (max-width: 567px) {
      margin-bottom: 10px;
    }
  }
  &__btn {
    text-transform: uppercase;
    background: none;
    border: none;
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 18px;
  }
  &__bg {
    width: 100%;
  }
  &__story-one {
    position: absolute;
    transition: transform 1s;
    animation: rightSlide 1s;

    @media (max-width: 567px) {
      position: initial;
    }

    &_1 {
      right: 50%;
      bottom: 200px;
      transform: translate(0, 160px) rotate(-30deg);
      @media (max-width: 1700px) {
        max-width: 340px;
      }
      @media (max-width: 1200px) {
        max-width: 240px;
        bottom: 120px;
      }
      @media (max-width: 768px) {
        max-width: 225px;
      }
      @media (max-width: 567px) {
        max-width: 195px;
      }

      &_animate {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
    &_2 {
      right: 50%;
      bottom: 162px;
      transform: translate(0, 160px) rotate(-30deg);
      @media (max-width: 1700px) {
        max-width: 440px;
      }
      @media (max-width: 1200px) {
        max-width: 240px;
        bottom: 120px;
      }
      @media (max-width: 768px) {
        max-width: 260px;
      }

      &_animate {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
    &_3 {
      right: 50%;
      bottom: 162px;
      transform: translate(0, 160px) rotate(-30deg);
      @media (max-width: 1700px) {
        max-width: 480px;
      }
      @media (max-width: 1200px) {
        max-width: 240px;
        bottom: 120px;
      }
      @media (max-width: 768px) {
        max-width: 280px;
      }

      &_animate {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
  }
  &__story-two {
    position: absolute;
    transition: transform 1s;
    animation: leftSlide 1s;

    @media (max-width: 567px) {
      top: 0;
      right: 0;
    }
    &_1 {
      left: 40%;
      bottom: 80px;
      transform: translate(0, 160px) rotate(30deg);
      @media (max-width: 1700px) {
        max-width: 460px;
      }
      @media (max-width: 1200px) {
        max-width: 350px;
        bottom: 70px;
      }
      @media (max-width: 768px) {
        max-width: 290px;
      }

      @media (max-width: 567px) {
        max-width: 240px;
      }
      &_animate {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
    &_2 {
      left: 43%;
      bottom: 162px;
      transform: translate(0, 160px) rotate(30deg);
      @media (max-width: 1700px) {
        max-width: 360px;
      }
      @media (max-width: 1200px) {
        max-width: 350px;
        bottom: 70px;
      }
      @media (max-width: 768px) {
        max-width: 220px;
      }

      &_animate {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
    &_3 {
      left: 43%;
      bottom: 162px;
      transform: translate(0, 160px) rotate(30deg);
      @media (max-width: 1700px) {
        max-width: 420px;
      }
      @media (max-width: 1200px) {
        max-width: 350px;
        bottom: 70px;
      }
      @media (max-width: 768px) {
        max-width: 232px;
      }

      &_animate {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
    @keyframes leftSlide {
      0% {
        transform: translate(0, 160px) rotate(30deg);
      }
      100% {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
    @keyframes rightSlide {
      0% {
        transform: translate(0, 160px) rotate(-30deg);
      }
      100% {
        transform: translate(0, 0px) rotate(0deg);
      }
    }
  }
  &__desc {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    color: var(--text-color);
    text-align: center;
    text-transform: uppercase;
    max-width: 1034px;
    margin: auto auto 200px auto;
    animation: toTop 1s;
    @media (max-width: 1200px) {
      margin: auto auto 60px auto;
    }
    @media (max-width: 567px) {
      margin: 40px auto 60px auto;
    }
    @keyframes toTop {
      0% {
        transform: translateY(50%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.hybrids {
  display: flex;
  gap: 90px;
  justify-content: center;
  color: var(--white);
  font-family: "Norwester";
  font-size: 26px;
  text-align: center;
  align-items: flex-end;
  @media (max-width: 567px) {
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;
  }
  &__plus {
    font-family: "Space Grotesk";
    font-size: 100px;
    line-height: 60px;
    margin: 34px 0;
  }
}

.info {
  margin-top: 120px;
  text-align: center;
  @media (max-width: 567px) {
    margin-top: 40px;
  }
  &__title {
    font-size: 40px;
    font-family: "Norwester";
    color: var(--yellow);
    text-transform: uppercase;
  }
  &__desc {
    max-width: 1034px;
    margin: 0 auto 80px auto;
    font-family: "Space Grotesk";
    font-weight: 700;
    text-transform: uppercase;
  }
  &__btns {
    display: flex;
    justify-content: center;
    gap: 16px;
    @media (max-width: 567px) {
      flex-direction: column;
    }
    a {
      justify-content: space-between;
      padding: 10px 30px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
}
