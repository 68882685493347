.contribution {
  padding-block-start: 120px;
  padding-block-end: 160px;
  font-family: "Space Grotesk";
  background-color: var(--primary-hot-pink);
  color: var(--primary-white);
  text-align: center;
  font-size: 19px;
  @media (max-width: 768px) {
    padding-block-end: 40px;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 3.187rem;
    color: var(--primary-happy-yellow);
    font-family: "Norwester", sans-serif;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 567px) {
      font-size: 26px;
    }
  }
  &__quote {
    max-width: 1260px;
    margin: 16px auto 24px auto;
    font-family: "Norwester", sans-serif;
    font-size: 80px;
    color: #202020;
    @media (max-width: 768px) {
      font-size: 42px;
    }
  }
  &__desc {
    max-width: 640px;
    margin: 0 auto 84px;
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    @media (max-width: 992px) {
      gap: 20px;
      margin-bottom: 40px;
    }
  }
  &__block {
    width: calc(100% / 3 - 40px);
    max-width: 320px;
    @media (max-width: 1400px) {
      width: calc(100% / 3 - 40px);
    }
    @media (max-width: 568px) {
      width: 100%;
      max-width: initial;
    }
    &-title {
      font-size: 23px;
      line-height: 23px;
      color: var(--text-color);
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    &-img-wrap {
      min-height: 142px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
    p {
      margin-top: 0;
    }
  }
  &__btns {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-bottom: 120px;
    @media (max-width: 567px) {
      flex-direction: column;
      margin-bottom: 70px;
    }
  }
  &__icon {
    position: relative;
    width: 1.25rem;

    &::before {
      content: "";
      display: block;
      padding-top: 90%;
    }
  }
}
