.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--primary-black);
  color: var(--primary-white);
  padding: 30px;
  border-radius: 30px;
  font-family: "Space Grotesk";
  font-size: 19px;
  line-height: 27px;
  max-height: calc(100vh - 100px);
  overflow: auto;
  width: 860px;
  &_show {
    visibility: initial;
  }
  @media (max-width: 960px) {
    width: 99%;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }

  &__close {
    width: 30px;
    height: 30px;
    background-color: initial;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border: none;
    @media (max-width: 768px) {
      position: sticky;
      border-radius: 50%;
    }
    svg {
      fill: var(--primary-happy-yellow);
      width: 24px;
    }
  }
}


.not-list {
  text-align: center;
  padding: 60px 60px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
  h3 {
    margin-bottom: 100px;
  }
  a {
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: var(--primary-white);
    text-decoration: none;
    svg {
      width: 24px;
      height: 15px;
      fill: var(--primary-white);
    }
  }
}

.freemint {
  text-align: center;
  padding: 60px 60px 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

  }
  &__item {
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width:768px) {
      gap: 8px;
      flex-direction: column;
    }
    span {
      min-width: 190px;
    }

  }
  &__btn {
    background-color: var(--primary-hot-pink);
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: none;
    padding: 16px;
    color: var(--primary-white);
    font-size: 19px;
    font-family: "Space Grotesk";
    margin: auto;
    cursor: pointer;
    transition: background-color 0.3s;
    @media (max-width: 400px) {
      width: 100%;
    }
    &:hover {
      background-color: var(--primary-white);
      color: var(--primary-black);
    }
  }
}

.public {
  padding: 60px 60px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
  &__wrap {
    margin-top: 140px;
    display: flex;
    justify-content: space-between;
  }
  h3 {
    text-align: center;
  }
  &__btn {
    background-color: var(--primary-hot-pink);
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    border: none;
    padding: 16px;
    color: var(--primary-white);
    font-size: 19px;
    font-family: "Space Grotesk";
    cursor: pointer;
    transition: background-color 0.3s;
    @media (max-width: 400px) {
      width: 100%;
    }
    &:hover {
      background-color: var(--primary-white);
      color: var(--primary-black);
    }
  }
  &__back {
    border: none;
    background-color: initial;
    color: var(--primary-white);
    font-family: 'Space Grotesk';
    font-size: 19px;
    cursor: pointer;
  }
}