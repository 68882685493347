.nav {
  @media (max-width: 1024px) {
    order: 2;
  }
}

.nav__list {
  display: flex;
  margin: 0;
  padding: 0;

  list-style-type: none;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
}

.nav__item {
  &:not(:last-child) {
    margin-right: 2.5rem;
  }

  @media (max-width: 1024px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.nav__item-link {
  display: block;
  border: none;
  padding: 0;

  color: var(--white);
  background-color: transparent;
  transition: var(--transition);

  cursor: pointer;
  font-family: 'Space Grotesk', sans-serif;
  text-transform: capitalize;
  text-decoration: none;

  &:hover,&:active,
  &--active {
    color: var( --primary-happy-yellow);
  }
}