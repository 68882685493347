.questions__image {
  position: relative;
  width: 100%;
  z-index: 0;

  &--mosaic {
    width: 74.418%;

    @media (max-width: 667px) {
      width: 100%;
    }
  }

  &--animate {
    animation-name: imageAnim ;
    animation-duration: var(--transition-extra-slow)
  }
}


