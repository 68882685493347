.share-sec {
  position: relative;
  background-image: url("../../assets/img/share/share.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  text-align: center;
  padding-bottom: 800px;
  &::before {
    content: "";
    background: linear-gradient(0deg, transparent, #010101);
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
  }
  
  @media (min-width: 424px) {
    height: 450px;
  }
  @media (max-width:567px) {
    padding-bottom: 340px;
  }
  &__desc {
    margin: auto auto 30px;
    max-width: 900px;
    padding-top: 120px;
    color: var(--pink);
    font-family: "Norwester";
    font-size: 26px;
    text-align: center;
    text-transform: uppercase;
    transition: transform 2.2s, opacity 2.4s;
    transform: translateY(-200px);
    opacity: 0;
    @media (max-width: 567px) {
      padding-top: 40px;
    }
    &_animate {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.share {
  text-align: center;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  &-btns {
    position: absolute;
    top: -84px;
    left: 50%;
    transform: translate(-50%, 0);
    visibility: hidden;
    background-color: black;
    padding: 14px 20px;
    border-radius: 50px;
    &_active {
      visibility: visible;
    }
  }
  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -8px;
    left: 50%;
    width: 24px;
    height: 24px;
    transform: translateX(-50%);
    background: none;
    background-color: #000;
    border-radius: 50%;
    color: var(--primary-white);
    border: none;
    cursor: pointer;
    div {
      transition: 0.3s transform;
      &:hover {
        transform: rotate(360deg);
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border: none;
    border-radius: 50%;
    transition: color 0.3s, background-color 0.3s;
    background-color: var(--primary-black);
    color: var(--primary-white);
    cursor: pointer;
    &:hover {
      background-color: var(--primary-white);
      color: var(--primary-black);
    }
  }
}
