.mint__content {
  width: 38.764%;
  flex-shrink: 0;

  @media (max-width: 667px) {
    width: 100%;
  }
}

.mint__title {
  margin-top: 0;
  margin-bottom: 1.25rem;

  color: var(--primary-white);

  font-family: "Space Grotesk", sans-serif;
  font-size: 1.687rem;
  line-height: 2.642rem;
  font-weight: 700;
}

.mint__desc {
  margin-top: 0;
  margin-bottom: 2.25rem;

  color: var(--primary-white);

  &.fz-19 {
    font-weight: 400;
  }
}

.mint__price {
  margin-top: 3.437rem;
  color: var(--primary-white);
  font-weight: 400;
  span {
    font-weight: bold;
  }
}

.mint__account {
  color: var(--primary-white);
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.487rem;
  span {
    display: inline-block;
  }
}

.mint__buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mint__button-label {
  overflow: hidden;
  position: relative;
  width: 47.984%;
  flex-shrink: 0;
  border-radius: 2rem;
}

.mint__input {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--primary-white);
  outline: 1px solid var(--primary-white);

  color: var(--primary-hot-pink);

  font-family: "Space Grotesk", sans-serif;
  font-size: 1.562rem;
  line-height: 2.446rem;
  font-weight: 700;

  &:disabled {
    opacity: 1;
  }
}

.mint__input-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 37.2%;
  border: none;
  padding: 0;
  cursor: pointer;

  color: var(--primary-black);
  background-color: var(--primary-white);

  &--minus {
    left: 0;
  }

  &--plus {
    right: 0;
  }
}

.mint__input-btn-img {
  position: relative;
  width: 1.712rem;

  svg {
    max-width: 100%;
    max-height: 100%;
    fill: var(--primary-black);
  }

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.mint__button {
  display: block;
  width: 47.984%;
  border-radius: 2rem;
  cursor: pointer;
  border: none;
  background-color: var(--primary-hot-pink);
  color: var(--primary-white);

  &.fz-19 {
    font-weight: 400;
  }
}

.mint__image-container {
  position: relative;
  width: 50%;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @media (max-width: 667px) {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.mint__image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  color: var(--primary-white);

  font-family: "Space Grotesk", sans-serif;
  font-size: 9.812rem;
  line-height: 15.437rem;
  font-weight: 700;
}
