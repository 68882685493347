.timer {
  background-color: var(--pink);
  display: flex;
  justify-content: center;
  color: var(--primary-hot-pink);
  font-weight: 700;
  font-family: "Space Grotesk";
  &__dots {
    font-size: 20px;
  }
  &_header {
    background: #000000;
    border: 2px solid #fd3474;
    padding: 7px 34px 7px 34px;
    border-radius: 50px;

    & .timer__value {
      line-height: 30px;
    }
  }
  &_default {
    & .timer__value {
      font-size: 100px;
      line-height: 100px;
      &_type {
        font-size: 26px;
        margin: 0;
      }
    }
    .timer__dots {
      font-size: 100px;
      line-height: 100px;
    }
    .timer__item {
      min-width: 160px;
    }
    @media (max-width: 768px) {
      min-width: 60px;
      & .timer__value {
        font-size: 48px;
        line-height: 48px;
        &_type {
          font-size: 18px;
          margin: 0;
        }
      }
      .timer__dots {
        font-size: 48px;
        line-height: 48px;
      }
      .timer__item {
        min-width: 80px;
      }
    }
    @media (max-width: 400px) {
      & .timer__value {
        font-size: 38px;
        line-height: 38px;
      }
      .timer__dots {
        font-size: 38px;
        line-height: 38px;
      }
      .timer__item {
        min-width: 50px;
      }
    }
  }
  &__title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &__item {
    text-align: center;
    min-width: 52px;
  }
  &__value {
    font-size: 30px;
    font-weight: 700;
    &_type {
      font-size: 12px;
      margin: 0;
    }
  }
}

.time-wrap {
  display: flex;
  align-items: center;
  gap: 40px;
}
