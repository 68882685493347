:root {
  --primary-hot-pink: rgba(253, 52, 116, 1);
  --primary-pink: rgba(255, 164, 182, 1);
  --primary-happy-yellow: rgba(249, 230, 58, 1);
  --primary-white: rgba(255, 255, 255, 1);
  --yellow: #f9e63a;
  --white: #ffffff;
  --pink: #e62666;
  --primary-black: rgba(0, 0, 0, 1);
  --black: #000;
  --black-sec: #171717;
  --black-third: #1b1c1a;
  --section-bg: #202020;
  --section-bg-sec: #1c1c1c;
  --text-color: #1f1f1f;
  --gray: #cccbd1;
  --primary-scorpion: rgba(86, 86, 86, 1);
  --primary-caribbean-green: rgba(0, 186, 175, 1);
  --primary-mercury: rgba(229, 229, 229, 1);
  --primary-french-grey: rgba(204, 203, 209, 1);
  --primary-gradient: linear-gradient(
    343.39deg,
    #eab9d1 6.32%,
    #e6b9d0 18.44%,
    #dbb9cf 30.55%,
    #c8b9cc 40.93%,
    #aeb9c8 52.18%,
    #8cb9c3 62.56%,
    #62babd 73.81%,
    #32bab6 83.33%,
    #00baaf 92.85%
  );
  --primary-gradient-two: linear-gradient(
    180deg,
    #eab9d1 0%,
    #e6b9d0 16.96%,
    #dbb9cf 33.92%,
    #c8b9cc 48.46%,
    #aeb9c8 64.21%,
    #8cb9c3 78.74%,
    #62babd 94.49%,
    #32bab6 107.82%,
    #00baaf 121.14%
  );
  --transition: 300ms;
  --transition-slow: 800ms;
  --transition-extra-slow: 1200ms;

  --tablet: 1024px;
}

@font-face {
  font-family: "Nafta Light";
  src: url("./assets/font/NaftaLight-Regular.woff2") format("woff2"),
    url("./assets/font/NaftaLight-Regular.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/font/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("./assets/font/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/font/SpaceGrotesk-Medium.woff2") format("woff2"),
    url("./assets/font/SpaceGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/font/SpaceGrotesk-Light.woff2") format("woff2"),
    url("./assets/font/SpaceGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/font/SpaceGrotesk-SemiBold.woff2") format("woff2"),
    url("./assets/font/SpaceGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/font/SpaceGrotesk-Regular.woff2") format("woff2"),
    url("./assets/font/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Norwester";
  src: url("./assets/font/Norwester-Regular.woff2") format("woff2"),
    url("./assets/font/Norwester-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  scroll-behavior: smooth;

  font-size: max(10px, 0.923vw);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 1024px) {
    font-size: max(8px, 1.562vw);
  }

  @media (max-width: 667px) {
    font-size: max(8px, 2.398vw);
  }
}

body {
  min-height: calc(var(--vh) * 100);
  width: 100%;
  font-family: "Space Grotesk";
  margin: 0;
  font-weight: 400;
  font-size: 19px;
  background-color: var(--section-bg);
  background-repeat: repeat;
  overflow-x: hidden;
  background-size: 100% 100%;
}

h2 {
  font-weight: 300;
}

.img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 100%;

  img,
  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.hidden {
  display: none;
}

.hide-md {
  @media (max-width: 1024px) {
    display: none;
  }
}

.page-container {
  width: 92%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 91.991%;
  }
}

.fz-19 {
  font-family: "Space Grotesk", sans-serif;
  font-size: max(16px, 1.187rem);
  line-height: max(18px, 1.859rem);
  font-weight: 700;
}

@keyframes rotate {
  from {
    transform: translateX(-50%) translateY(-29%) rotate(0);
  }
  to {
    transform: translateX(-50%) translateY(-29%) rotate(360deg);
  }
}

@keyframes rotateHeader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes imageAnim {
  from {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes joinTitleAnim {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes aboutImgAnim {
  from {
    opacity: 0;
    transform: translateX(-25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes dropdownAnim {
  from {
    max-height: 0;
  }

  to {
    max-height: 1000px;
  }
}

@keyframes sliderImageAnimate {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anatomyTextImgAnim {
  from {
    opacity: 0;
    transform: translateY(-25%) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes anatomyImgAnim {
  from {
    opacity: 0;
    transform: translateX(-25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes questionsContentAnimate {
  from {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes headingImgAnim {
  from {
    transform: rotate(0) translateX(-20px);
  }
  to {
    transform: rotate(90deg) translateX(0);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.relative {
  position: relative;
}

.rarity {
  .swiper-slide {
    img {
      transform: scale(0.5);
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    img {
      transform: scale(0.8);
      @media (max-width: 560px) {
        transform: scale(1.6) translate(0, 50%);
      }
    }
  }

  .swiper-slide-active {
    img {
      transform: scale(1);
      opacity: 1 !important;
      @media (max-width: 560px) {
        transform: scale(2) translate(0, 60px);
      }
    }
    h3 {
      text-transform: uppercase;
      display: block !important;
      @media (max-width: 560px) {
        margin-top: 244px;
      }
    }
  }
}

.sharethis-inline-share-buttons > div {
  display: block !important;
}

.text-white {
  color: var(--primary-white);
}

button {
  font-family: Space Grotesk;
  font-size: 19px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  cursor: pointer;
}

img {
  max-width: 100%;
}

.btn {
  display: inline-flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: var(--gray);
  color: var(--black);
  min-width: 246px;
  height: 60px;
  transition: 0.3s;
  text-decoration: none;
  border: none;
  .sharethis-inline-share-buttons .svg {
    width: 18px;
    height: 17px;
  }
  &:hover {
    background-color: var(--white);
  }
}

.btn_gray {
  position: relative;
  background-color: var(--gray);
  &:hover {
    background-color: var(--black);
    color: var(--white);
    svg {
      fill: var(--white);
    }
  }
}
.btn_white {
  position: relative;
  background-color: var(--white);
  &:hover {
    background-color: var(--black);
    color: var(--white) !important;
    svg {
      fill: var(--white);
    }
  }
}
.btn_pink {
  position: relative;
  background-color: var(--pink);
  color: var(--white);
  &:hover {
    background-color: var(--white);
    color: var(--black);
    svg {
      fill: var(--black);
    }
  }
}

.btn_contact-us {
  color: var(--black) !important;
  margin: 200px auto 100px auto;
  justify-content: center;
}

.text-center {
  text-align: center;
}

@keyframes fadeInTop {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.chevron {
  &-r {
    transition: transform 0.3s;
    &:hover {
      transform: translateX(2px);
    }
  }
  &-l {
    transition: transform 0.3s;
    &:hover {
      transform: translateX(-2px);
    }
  }
}
