.team {
  padding-top: 20rem;
  background-color: var(--section-bg-sec);
  @media (max-width: 768px) {
    padding-bottom: 4rem;
  }
}

.team-wrapper {
  padding-bottom: 5.312rem;
  margin-bottom: -16.312rem;
  color: var(--primary-white);
}

.team__container  {
  transform: translateY(-16.312rem);
}

.team__title {
  margin-top: 0;
  margin-bottom: 3.187rem;

  color: var(--primary-white);

  font-family: 'Norwester', sans-serif;
  font-size: 80px;
  line-height: 3.915rem;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 567px) {
    font-size: 26px;
  }
}