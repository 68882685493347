.faq {
  height: 100%;
  overflow-y: auto;
  background-image: url("../../../assets/img/background.png");
  background-repeat: repeat;
}

.mint-video {
  display: flex;
  height: 600px;
  margin: auto;
}
