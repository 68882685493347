.roadmap__swiper {
  width: 0;

  .swiper-slide {
    width: min-content !important;
  }

  .swiper-slide-active .roadmap__swiper-sub-title {
    opacity: 1;
  }

  .swiper-slide-active .roadmap__swiper-title span {
    color: var(--primary-happy-yellow);
  }

  .swiper-slide-active .roadmap__swiper-inner-container {
    opacity: 1;
  }

  @media (max-width: 667px) {
    padding-left: 4.004%;
  }
}

.roadmap__swiper-btn-text {
  color: var(--primary-white);

  &.fz-19 {
    font-weight: 400;
  }
}

.roadmap__swiper-btn--next {
  display: flex;
  align-items: center;
  gap: 18px;
  position: absolute;
  top: 12.125rem;
  z-index: 10;
  padding: 0;

  background-color: transparent;
  border: none;

  &:hover {
    svg {
      fill: var(--primary-hot-pink);
    }
  }

  @media (max-width: 1024px) {
    top: unset;
    bottom: 50%;
    right: 4.004%;
  }

  @media (max-width: 667px) {
    bottom: 0;
  }

  .roadmap__swiper-btn-icon {
    margin-left: 1.375rem;
  }

  right: 9%;
}

.roadmap__swiper-btn--prev {
  display: flex;
  align-items: center;
  position: absolute;
  top: 12.125rem;
  padding: 0;

  background-color: transparent;

  border: none;

  &:hover {
    svg {
      fill: var(--primary-hot-pink);
    }
  }

  @media (max-width: 1024px) {
    top: unset;
    bottom: 50%;
    left: 4.004%;
  }

  @media (max-width: 667px) {
    bottom: 0;
  }
  .roadmap__swiper-btn-icon {
    margin-right: 1.375rem;
  }

  left: 9%;
  z-index: 10;
}

.roadmap__swiper-btn-icon {
  position: relative;
  width: 1.687rem;

  svg {
    fill: var(--primary-white);
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.roadmap__swiper-container {
  display: flex;
  flex-direction: column;
  width: min-content;
  padding-bottom: 3rem;
}

.roadmap__swiper-inner-container {
  opacity: 0;
  transition: var(--transition);
  width: 26.75rem;

  @media (max-width: 667px) {
    width: 20rem;
  }
}

.roadmap__swiper-sub-title {
  display: inline-flex;
  opacity: 0;
  transition: var(--transition);
  margin-top: 0;
  margin-bottom: 0;

  color: var(--primary-white);
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.687rem;
  line-height: 2.642rem;
  white-space: nowrap;
}

.roadmap__swiper-title {
  display: inline-flex;
  margin-top: -0.5rem;
  margin-bottom: 1.581rem;
  transition: var(--transition);

  color: var(--primary-white);

  font-family: 'Norwester', sans-serif;
  font-size: 8.281rem;
  line-height: 8.91rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 667px) {
    font-size: 6.281rem;
    line-height: 6.91rem;
  }
}

.roadmap__swiper-desc-title {
  margin-top: 0;
  margin-bottom: 0.625rem;

  color: var(--primary-white);
}

.roadmap__swiper-desc {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--primary-white);
  @media (max-width: 667px) {
    width: 92vw;
  }
  a {
    color: var(--primary-white);
  }

  &.fz-19 {
    font-weight: 400;
  }
}

.roadmap__swiper-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.roadmap__swiper-item {
  color: var(--primary-white);
}