
.team__swiper-container {
  position: relative;
}

.team__swiper {
  position: static !important;
  width: 100%;

  .swiper-pagination-bullets {
    display: none;

    @media (max-width: 667px) {
      display: block;
      top: 103%;
      bottom: unset;
    }

    .swiper-pagination-bullet {
      background-color: var(--primary-pink);
    }

    .swiper-pagination-bullet-active {
      background-color: var(--primary-hot-pink);
    }
  }
}

.team__swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.687rem;

  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: 667px) {
    width: 3.687rem;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: var(--white);
    stroke: var(--white);
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &--prev {
    left: 0;

    @media (max-width: 667px) {
      right: 96%;
      left: unset;
    }
  }

  &--next {
    right: 0;

    @media (max-width: 667px) {
      left: 96%;
      right: unset;
    }
  }

  &:hover {
    svg {
      fill: var(--primary-hot-pink);
      stroke: var(--primary-hot-pink);
    }
  }
}

.team__slider-container {
  width: 100%;
}

.team__slider-img {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
  &--animate {
    animation-name: sliderImageAnimate;
  }

  @for $i from 1 through 4 {
    &--#{$i} {
      animation-duration: 900 + $i * 300ms;
    }
  }

}


.team__slider-header {
  margin-bottom: 1.913rem;
}

.team__slider-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team__slider-name {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--yellow);

  font-family: 'Norwester', sans-serif;
  font-size: 26px;
  text-transform: uppercase;
}

.team__slider-icons {
  display: flex;
}

.team__slider-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.921rem;
  height: 1.921rem;
  margin-right: 0.2rem;
  border-radius: 100%;
  background-color: transparent;
  transition: var(--transition);

  &:hover {
    background-color: var(--white);

    svg {
      fill: var(--primary-happy-yellow);
    }
  }

  @media (max-width: 667px) {
    width: 2.921rem;
    height: 2.921rem;
  }
}

.team__slider-icon-container {
  position: relative;
  width: 20px;
  display: block;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 1.875rem;
  }

  svg {
    fill: var(--white);
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &--insta {
    width: 14.15px;
  }

  &--behance {
    width: 20px;
  }
}

.team__slider-position {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--white);

  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  &.fz-19 {
    font-weight: 400;
  }
}

.team__slider-desc {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--white);

  font-family: 'Space Grotesk', sans-serif;

  a {
    color: var(--white);
  }

  &.fz-19 {
    font-weight: 400;
  }
}