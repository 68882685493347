.footer {
  position: relative;
  z-index: 11;
}

.footer__scroll-btn {
  position: absolute;
  right: 1rem;
  bottom: 8.437rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;

  background-color: var(--primary-black);
  border: none;

  @media (max-width: 414px) {
    bottom: 12rem;
  }
}

.footer__scroll-btn-container {
  position: relative;
  width: 1.125rem;

  svg {
    fill: var(--primary-white);
    stroke: var(--primary-white0);
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.footer__logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-29%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 100%;

  cursor: pointer;
  border: none;
  background-color: var(--gray);

  &--visible {
    animation-name: rotate;
    animation-duration: var(--transition-slow);
  }
}

.footer__logo-img-container {
  position: relative;
  width: 64%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.footer-top {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: var(--gray);
}

.footer-top__container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 667px) {
    flex-direction: column;
  }
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  &--links {
    width: 28.794%;
    justify-content: space-between;

    @media (max-width: 667px) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &--form {
    width: 58.705%;
    @media (max-width: 667px) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

.footer__block {
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    @media (max-width: 667px) {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 667px) {
    margin-top: 2rem;
  }
}

.footer__title {
  margin-top: 0;
  margin-bottom: 1.312rem;

  color: var(--primary-black);

  font-family: 'Norwester', sans-serif;
  font-size: max(18px, 1.875rem);
  line-height: max(20px, 2.937rem);
  text-transform: uppercase;
}

.footer__desc {
  margin-top: 0;
  margin-bottom: 2.812rem;

  &.fz-19 {
    font-weight: 400;
  }
}


.footer__nav-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__nav-item {

}

.footer__nav-link {
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  transition: var(--transition);

  &:hover {
    color: var(--primary-happy-yellow);
  }

  &.fz-19 {
    font-weight: 500;
  }
}

.footer__icons-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__icons-item {
  &:not(:last-child) {
    margin-right: 0.312rem;
  }
}

.footer__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.921rem;
  height: 1.921rem;
  border-radius: 100%;
  background-color: transparent;
  transition: var(--transition);

  &:hover {
    background-color: var(--primary-black);

    svg {
      fill: var(--primary-happy-yellow);
    }
  }

  @media (max-width: 667px) {
    width: 2.921rem;
    height: 2.921rem;
  }
}

.footer__icon-container {
  position: relative;
  display: block;
  z-index: 1;

  text-decoration: none;

  &::before {
    content: '';
    display: block;
    //padding-top: 100%;
  }

  &--discord {
    width: 18.4px;

    &::before {
      padding-top: 76.141%;
    }
  }

  &--insta {
    width: 14.15px;

    &::before {
      padding-top: 100%;
    }

    &:hover {
      .footer__icon-back {
        opacity: 1;
      }

      svg {
        fill: var(--primary-happy-yellow);
      }
    }
  }

  &--twitter {
    width: 13.08px;

    &::before {
      padding-top: 82.123%;
    }
  }

  &--tiktok {
    width: 12.06px;

    &::before {
      padding-top: 116.804%;
    }
  }
}

.footer-bottom {
  padding-top: 1.718rem;
  padding-bottom: 1.718rem;

  background-color: var(--primary-black);
}

.footer-bottom__container {
  display: flex;
  justify-content: space-between;
}

.footer-bottom__text {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--primary-white);

  &.fz-19 {
    font-weight: 400;

    a {
      color: var(--primary-white);
      text-decoration: none;
      font-weight: 700;
    }
  }
}