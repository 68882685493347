.roadmap {
  padding-bottom: 3.687rem;
  @media (max-width: 768px) {
    padding-bottom: 8.687rem;
  }
}

.roadmap__title {
  margin-top: 0;
  margin-bottom: 3.25rem;

  color: var(--primary-white);

  font-family: 'Norwester', sans-serif;
  font-size: 2.5rem;
  line-height: 3.915rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: normal;
  color: var(--yellow);
}
