.intro {
  background-color: var(--pink);
  min-height: 100vh;
  position: relative;
  @media (max-width: 567px) {
    padding-bottom: 20px;
  }
  &__desc {
    position: absolute;
    width: 375px;
    z-index: 10;
    top: 40%;
    color: var(--primary-white);
    @media (max-width: 992px) {
      max-width: 375px;
      position: initial;
      text-align: center;
      width: auto;
      margin: auto;
      margin-bottom: 44px;
    }
  }
  &__divide {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transform: translate(0, 98%);
  }
  &.hide-bg {
    &::after {
      display: none;
    }
  }
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 100vh;
    @media (max-width: 1200px) {
      object-fit: contain;
    }
  }
  &__wrap {
    position: absolute;
    bottom: 10%;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 992px) {
      position: initial;
    }
  }
  &__btn-mute {
    gap: 26px;
    background: transparent;
    border: none;
    cursor: pointer;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    &:hover {
      background-color: var(--black);
      span {
        background-color: var(--white);
        &::before {
          border-color: var(--white);
        }
        &::after {
          border-color: transparent var(--white) transparent transparent;
        }
      }
      .intro__btn-mute__speaker {
        &::before {
          border-color: var(--white);
        }
      }
    }
    &__speaker {
      height: 30px;
      width: 30px;
      position: relative;
      overflow: hidden;
      display: inline-block;

      span {
        display: block;
        width: 8px;
        height: 8px;
        background: var(--black);
        margin: 11px 0 0 2px;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent var(--black) transparent transparent;
          border-width: 10px 14px 10px 15px;
          left: -13px;
          top: 5px;
        }

        &:before {
          transform: rotate(45deg);
          border-radius: 0 50px 0 0;
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-style: double;
          border-color: var(--black);
          border-width: 7px 7px 0 0;
          left: 18px;
          top: 9px;
          transition: all 0.2s ease-out;
          box-sizing: initial;
        }
      }

      &.muted {
        span:before {
          transform: scale(0.5) translate(-15px, 0) rotate(36deg);
          opacity: 0;
        }
      }
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    gap: 26px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 992px) {
      display: none;
    }

    &__play {
      overflow: hidden;
      background-color: var(--white);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      transition: background-color 0.3s, border-color 0.3s, transform 0.9;

      &:hover {
        background-color: var(--black);
        span {
          border-color: transparent transparent transparent var(--white);
          animation: fadeInL 0.5s;
          @keyframes fadeInL {
            0% {
              transform: scale(0.7);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
      span {
        display: block;
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        border-color: transparent transparent transparent var(--black);
        transition: 100ms all ease;
        cursor: pointer;

        border-style: solid;
        border-width: 17px 0 17px 30px;
        margin-left: 6px;
        &.paused {
          margin-left: 0;
          border-style: double;
          border-width: 0px 0 0px 30px;
          height: 40px;
        }

        &:hover {
          border-color: transparent transparent transparent var(--white);
        }
      }
    }

    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 19px;
      border: none;
      color: var(--white);
    }
  }
}

.center-block {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -40%);
  z-index: 10;
  @media (max-width: 992px) {
    padding-top: 100px;
    position: initial;
    transform: translate(0);
  }
  &__logos {
    max-width: 660px;
    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
  &__quad {
    width: 100%;
    position: absolute;
    left: -60px;
    bottom: -40px;
    max-width: 460px;
    animation: drive 0.8s;
  }
}

@keyframes drive {
  0% {
    transform: translate(-50px, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.r-side {
  position: absolute;
  right: 0;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  @media (max-width: 567px) {
    display: none;
  }
  &__tv {
    max-width: 220px;
    animation: fadeIn 1s;
  }
  &__character {
    position: relative;
    z-index: 100;
    right: -60px;
    top: 26px;
    width: 160px;
    animation: character 1.3s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes character {
  0% {
    transform: translate(0, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
